import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { useFetchingComponentState, useRoleContext, useSubscriptionStatus } from 'hooks';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { DataPageSwitcher } from '../DataPageSwitcher';
import { ShipperUpgradeRequestInfoButton } from '../ShipperUpgradePage/components';

interface RoleHasDatasetResponse {
  has_dataset: boolean
}

export function DataPageWrapper() {
  const { role } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const {
    error, fetch, success,
  } = useFetchingComponentState<RoleHasDatasetResponse>({
    errorMessage: 'Failed to check dataset availability. Please contact us',
    fetchConfig: { url: `/shippers/${role?.id || 0}/viz/has-dataset` },
  });
  const [hasDataset, setHasDataset] = useState<null | boolean>(null);

  useEffect(() => {
    if (role != null && hasDataset == null && !success && error == null) {
      fetch((res: RoleHasDatasetResponse) => {
        setHasDataset(res.has_dataset);
      });
    }
  }, [role, hasDataset, setHasDataset, fetch, success, error]);

  if (subscription === null) {
    return <div />;
  }

  if ((subscription.subscription_plan === 'SHIPPER_TRIAL' && !subscription.is_active) || subscription.subscription_plan === 'SHIPPER_FREE_TIER' || subscription.subscription_plan === 'SHIPPER_SHIP_A'
    || subscription.subscription_plan === 'SHIPPER_SHIP_B' || subscription.subscription_plan === 'SHIPPER_SHIP_C') {
    return (
      <PageLayout>
        <Stack
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            p="2rem"
            direction="column"
            justifyContent="center"
          >
            <Typography
              textAlign="center"
              variant="h4"
            >
              The Analytics dashboard is a Premium feature.
            </Typography>
            <Typography
              textAlign="center"
              variant="h4"
              sx={{ mt: '2rem', mb: '2rem' }}
            >
              Go Premium for powerful insights and more!
            </Typography>
          </Stack>
          <ShipperUpgradeRequestInfoButton />
        </Stack>
      </PageLayout>
    );
  }

  if (hasDataset == null) {
    if (error !== null) {
      return (
        <Stack>
          <ErrorTypography error={error} />
        </Stack>
      );
    }
    return (<div />);
  }

  if (hasDataset === false) {
    return (
      <PageLayout>
        <Stack
          p="2rem"
        >
          <Typography
            textAlign="center"
            variant="h4"
            sx={{ mt: '3rem' }}
          >
            You do not have any data to analyze yet.
            Start by posting and booking some shipments then come back to analyze the results.
          </Typography>
        </Stack>
      </PageLayout>
    );
  }
  return <DataPageSwitcher />;
}
