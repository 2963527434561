import { Typography } from '@mui/material';
import { useFetchingComponentState, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { unixEpochToLocalReadableTimestamp } from 'utils';

export function DataFreshnessLabel() {
  const { role } = useRoleContext();
  const [datasetComputedAt, setDatasetComputedAt] = useState<null | string>(null);
  const {
    fetch,
  } = useFetchingComponentState<DataSetFilterResponse>({
    errorMessage: '',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/viz/dataset-filters` : '/',
    },
  });

  useEffect(() => {
    if (role && datasetComputedAt == null) {
      fetch((res) => setDatasetComputedAt(
        unixEpochToLocalReadableTimestamp(res.dataset_created_at_unix_epoch * 1000),
      ));
    }
  }, [role, datasetComputedAt, fetch]);

  if (!datasetComputedAt) {
    return <div />;
  }

  return (
    <Typography variant="body2" color="text.secondary">
      Data last updated at
      {' '}
      {datasetComputedAt}
      .
    </Typography>
  );
}
