import {
  useCallback, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, usePasswordFieldToggle } from '../../../../hooks';
import { validateEmail } from '../../../../utils';
import { EmailAndPasswordFormErrorState } from '../../types';
import { validatePassword } from '../utils';
import { SignUp } from './SignUp';
import { SignUpData } from './types';

const validationFns: { [key: string]: (val: string) => string | null } = {
  email: validateEmail,
  password: validatePassword,
};

export function SignUpContainer() {
  const navigate = useNavigate();
  const { signup } = useAuthContext();
  const [signupError, setSignupError] = useState<null | string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();
  const [form, setForm] = useState<SignUpData>({
    email: '',
    confirmEmail: '',
    password: '',
    firstName: '',
    lastName: '',
    role: 'SHIPPER',
  });

  const [formError, setFormError] = useState<EmailAndPasswordFormErrorState>({
    email: null,
    password: null,
  });

  const handleTermsChange = useCallback((newValue: boolean) => {
    setTermsAccepted(newValue);
  }, []);

  const handleFormChange = useCallback((key: keyof SignUpData, value: string) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (['email', 'password'].includes(key)) {
      setFormError((prev) => ({
        ...prev,
        [key]: validationFns[key](value),
      }));
    }
  }, []);

  const handleSignup = () => {
    const {
      email, password, firstName, lastName, role, confirmEmail,
    } = form;

    if (email !== confirmEmail) {
      setSignupError('Emails do not match.');
      return;
    }

    signup({
      email, first_name: firstName, last_name: lastName,
    }, password, role).then(() => {
      setSignupError(null);
      navigate('/sign-up/success');
    }).catch(
      (e) => {
        setSignupError(e?.response?.data?.message || 'There was an error while creating your account. Please contact us if the issue persists.');
      },
    );
  };

  return (
    <SignUp
      onFormChange={handleFormChange}
      onSignup={handleSignup}
      role={form.role}
      formError={formError}
      showPassword={passwordIsVisible}
      onShowPasswordChange={handleShowPasswordChange}
      signupDisabled={form.email.length === 0
        || form.password.length === 0
        || form.firstName.length === 0
        || form.lastName.length === 0
        || form.role.length === 0
        || formError.email != null
        || formError.password != null
        || !termsAccepted}
      signupError={signupError}
      onTermsChange={handleTermsChange}
    />
  );
}
