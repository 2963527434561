import { EQUIPMENT_VALUE_TO_LABEL_MAP, PROVINCE_STATE_VALUE_TO_LABEL_MAP, SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP } from '../../../../../../constants';

function sortByCount(attributeCounts: DistinctAttributeCount[]) {
  return attributeCounts.sort((
    a: DistinctAttributeCount,
    b: DistinctAttributeCount,
  ) => (b.count - a.count));
}

export function equipmentTypeFiltersToEquipmentTypeOptions(equipments: DistinctAttributeCount[]) {
  return sortByCount(equipments).map((equipment: DistinctAttributeCount) => (
    {
      value: equipment.attribute,
      label: `${EQUIPMENT_VALUE_TO_LABEL_MAP[equipment.attribute]}`,
    }
  ));
}

export function provinceStateFiltersToProvinceStateOptions(provinces: DistinctAttributeCount[]) {
  return sortByCount(provinces).map((province: DistinctAttributeCount) => (
    {
      value: province.attribute,
      label: `${PROVINCE_STATE_VALUE_TO_LABEL_MAP[province.attribute]} `,
    }
  ));
}

export function serviceFiltersToShipmentServiceOption(services: DistinctAttributeCount[]) {
  return sortByCount(services).map((service: DistinctAttributeCount) => ({
    value: service.attribute,
    label: `${SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP[service.attribute]}`,
  }));
}
