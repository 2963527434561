import { Stack } from '@mui/material';
import { useFilteredViz } from 'hooks';
import { CHART_DEFAULT_HEIGHT, CHART_DEFAULT_WIDTH } from '../constants';
import { MeanPriceOverTimeGraph } from '../MeanPriceOverTimeGraph';
import { MeanPriceOverTime } from './MeanPriceOverTime';

interface Props {
  filterState: VizFilterState;
  useCAD: boolean;
  containerWidth: number | undefined;
}

export function MeanPriceOverTimeContainer(props: Props) {
  const { filterState, useCAD, containerWidth } = props;
  const {
    error, vizData,
  } = useFilteredViz<PriceOverMonthVizResponse>('price-over-time', filterState);

  const vizContainerHeight = CHART_DEFAULT_HEIGHT;
  const vizHeight = CHART_DEFAULT_HEIGHT * 0.9;
  const vizWidth = containerWidth ? containerWidth * 0.88 : CHART_DEFAULT_WIDTH;

  return (
    <Stack
      height={vizContainerHeight}
    >
      <MeanPriceOverTime
        viz={(
          <MeanPriceOverTimeGraph
            result={vizData}
            useCAD={useCAD}
            width={vizWidth}
            height={vizHeight}
          />
)}
        error={error}

      />
    </Stack>

  );
}
