import { Typography } from '@mui/material';

export function NoDataFoundForFiltersTypography() {
  return (
    <Typography m="2rem" textAlign="center">
      No data found for chosen filters.
      Please select another filter combination
    </Typography>
  );
}
