import { Stack, Switch, Typography } from '@mui/material';

interface Props {
  useCAD: boolean;
  onUpdate: (useCad: boolean) => void;
}
export function CurrencyToggle(props: Props) {
  const { useCAD, onUpdate } = props;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography><b>CAD</b></Typography>
      <Switch
        checked={!useCAD}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onUpdate(!event.target.checked);
        }}
      />
      <Typography><b>USD</b></Typography>
    </Stack>
  );
}
