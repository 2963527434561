import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Divider, Grid, Stack, Typography,
} from '@mui/material';
import { ShipmentStatusBadge } from 'components/ShipmentStatusBadge';
import { ShipmentCustomNameText } from 'components/Texts/ShipmentCustomNameText';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentFootageText } from 'components/Texts/ShipmentFootageText';
import { ShipmentIDText } from 'components/Texts/ShipmentIDText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentPriceText } from 'components/Texts/ShipmentPriceText';
import { ShipmentWeightText } from 'components/Texts/ShipmentWeightText';
import { useNavigate } from 'react-router-dom';
import { countryIsFullySupported, formatText } from 'utils';
import { getServiceTypeDisplayLabel } from '../../../../constants';
import { getCommdityNameForCondensedShipmentShipment } from '../../../../utils';
import { MultiShipmentProjectLoadsAvailableLoadsLabel } from '../MultiShipmentProjectLoadsAvailableLoadsLabel';
import { QuoteCountChip } from '../QuoteCountChip';
import { SharedShipmentChip } from '../SharedShipmentChip';

interface Props {
  shipment: ShipperCondensedShipment;
}

export function ShipmentPreview(props: Props) {
  const navigate = useNavigate();
  const { shipment } = props;
  const shipmentInfo = shipment.info;
  const firstPointLocation = shipment?.first_point?.point_location;
  const lastPointLocation = shipment?.last_point?.point_location;

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
      onClick={(event) => {
        if ((event.target as HTMLInputElement).className === 'quotes') {
          navigate(`/shipments/${shipmentInfo.id}?focus=quotes`);
        } else {
          navigate(`/shipments/${shipmentInfo.id}`);
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Stack spacing={1}>
            <MultiShipmentProjectLoadsAvailableLoadsLabel shipment={shipment} />
            <ShipmentEquipmentTypeText data={shipment?.equipments} />
            <Typography sx={{ textTransform: 'capitalize' }}>{getServiceTypeDisplayLabel(shipmentInfo.service)}</Typography>
            <ShipmentWeightText data={shipment?.info?.weight_lb} />
            <ShipmentIDText shipment={shipment} />
            {shipment && shipment.custom_name != null && (
            <ShipmentCustomNameText data={shipment.custom_name} />
            ) }
          </Stack>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Box
            ml="2rem"
            flex={1}
            alignSelf="center"
          >
            <Typography
              sx={{ textTransform: 'capitalize' }}
              variant="body1"
            >
              {formatText(getCommdityNameForCondensedShipmentShipment(shipmentInfo))}
            </Typography>
            <Stack my="0.5rem" direction="row" alignItems="center">
              <ShipmentLocationText
                data={countryIsFullySupported(firstPointLocation.country) ? `${firstPointLocation.city}, ${firstPointLocation.province}` : `${firstPointLocation.city}, ${firstPointLocation.country}`}
                icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
              <Box flex={1} mx="1rem">
                <Divider />
              </Box>
              <ShipmentLocationText
                data={countryIsFullySupported(lastPointLocation.country) ? `${lastPointLocation.city}, ${lastPointLocation.province}` : `${lastPointLocation.city}, ${lastPointLocation.country}`}
                icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
            </Stack>
          </Box>
          <Stack direction="row" spacing={4} mx="2rem" justifySelf="flex-end" alignSelf="center">
            <ShipmentFootageText data={shipment?.info?.footage} />
            <ShipmentPriceText
              price={shipment?.info?.preferred_price}
              currency={shipment?.info?.currency}
            />
          </Stack>
          <Stack direction="row" spacing={1} mx="2rem" mt="0.5rem">
            <ShipmentStatusBadge status={shipment?.shipment_status} size="medium" />
            <QuoteCountChip quoteCount={shipment?.condensed_quotes.filter(
              (q) => q.hidden_at == null,
            ).length}
            />
            <SharedShipmentChip shipment={shipment} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
