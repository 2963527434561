import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyDetails } from './CompanyDetails';

export function CompanyDetailsContainer() {
  const params = useParams();
  const companyId = params.id;
  const [company, setCompany] = useState<CompanyDetailInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useAPI();

  useEffect(() => {
    if (loading) {
      api<CompanyDetailInfo>('get', `/companies/${companyId}`).then((response) => {
        setCompany(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch company data.');
      });
      setLoading(false);
    }
  }, [loading, api, companyId]);

  return (
    <CompanyDetails
      company={company}
      error={error}
      loading={loading}
    />
  );
}
