import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';

interface Props {
  viz: React.ReactNode;
  error: UIError;
}

export function MeanPriceOverTime(props: Props) {
  const {
    viz, error,
  } = props;

  return (
    <Stack
      id="root-mean-price-over-time-container"
      direction="column"
      width="100%"
      height="100%"
    >
      <Typography variant="h5" textAlign="center">Average Booked vs Asked Price</Typography>
      <ErrorTypography error={error} />
      {viz}
    </Stack>
  );
}
