import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

interface VizFilterQueryParamsObj {
  equipment_type: string[] | null;
  origin_province: string[] | null;
  destination_province: string[] | null;
  service: string[] | null;
}

export function useFilteredViz<T>(vizName: string, filters: VizFilterState) {
  const { role } = useRoleContext();
  const [vizData, setVizData] = useState<T | null>(null);
  const [queryParams, setQueryParams] = useState<string>('');
  const [fetchedForNewestParams, setFetchedForNewestParams] = useState<boolean>(false);
  const {
    error, fetch, success, loading,
  } = useFetchingComponentState<T>({
    errorMessage: 'Failed to load visualization. Please retry or contact us.',
    fetchConfig: { url: `/shippers/${role?.id || 0}/viz/${vizName}?${queryParams}` },
  });

  const buildVizFilterQueryParams = useCallback(() => {
    const filtersObject: VizFilterQueryParamsObj = {
      equipment_type: filters.equipmentTypes ?? null,
      origin_province: filters.origins ?? null,
      destination_province: filters.destinations ?? null,
      service: filters.services ?? null,
    };
    return qs.stringify(filtersObject, { skipNulls: true, arrayFormat: 'repeat' });
  }, [filters]);

  const refreshVizData = useCallback(() => {
    fetch((fetchedValue: T) => setVizData(fetchedValue));
    setFetchedForNewestParams(true);
  }, [fetch]);

  useEffect(() => {
    if (!fetchedForNewestParams) {
      refreshVizData();
    }
  }, [queryParams, refreshVizData, fetchedForNewestParams]);

  useEffect(() => {
    setFetchedForNewestParams(false);
  }, [filters, setQueryParams, buildVizFilterQueryParams]);

  useEffect(() => {
    setQueryParams(buildVizFilterQueryParams());
  }, [filters, setQueryParams, buildVizFilterQueryParams]);

  return {
    loading, error, success, vizData,
  };
}
