import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { EquipmentTypeMultiSelect } from 'components/EquipmentTypeSelect';
import { ProvinceStateMultiSelect } from 'components/ProvinceStateMultiSelect';
import { ShipmentServiceMultiSelect } from 'components/ShipmentServiceMultiSelect';
import { CurrencyToggle } from 'components/Shipper/components/Viz/CurrencyToggle';
import { useFetchingComponentState, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { equipmentTypeFiltersToEquipmentTypeOptions, provinceStateFiltersToProvinceStateOptions, serviceFiltersToShipmentServiceOption } from './utils';

interface Props {
  onFilterUpdate: (key: keyof VizFilterState, value: string[]) => void;
  filterState: VizFilterState;
  useCAD: boolean;
  onCurrencyUpdate: (useCad: boolean) => void;
}

export function DataPageFilters(props: Props) {
  const {
    onFilterUpdate, filterState, useCAD, onCurrencyUpdate,
  } = props;
  const { role } = useRoleContext();
  const [datasetFilters, setDatasetFilters] = useState<DataSetFilterResponse | null>(null);
  const {
    fetch,
  } = useFetchingComponentState<DataSetFilterResponse>({
    errorMessage: '',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/viz/dataset-filters` : '/',
    },
  });

  useEffect(() => {
    if (role && datasetFilters == null) {
      fetch((res) => setDatasetFilters(res));
    }
  }, [role, datasetFilters, fetch]);

  return (
    <Box p="1rem" sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Stack
        id="mean-price-over-time-filters-container"
        direction="row"
        spacing={1}
      >
        <Box>
          <EquipmentTypeMultiSelect
            onChange={(newVal: string[]) => onFilterUpdate('equipmentTypes', newVal)}
            defaultValue={filterState.equipmentTypes}
            value={filterState.equipmentTypes}
            customOptions={datasetFilters != null
              ? equipmentTypeFiltersToEquipmentTypeOptions(datasetFilters.equipment)
              : undefined}
          />
        </Box>
        <Box>
          <ShipmentServiceMultiSelect
            label="Services"
            value={filterState.services}
            onChange={(newValue) => onFilterUpdate('services', newValue)}
            customOptions={datasetFilters?.service ? serviceFiltersToShipmentServiceOption(
              datasetFilters.service,
            ) : undefined}
          />
        </Box>
        <Box>
          <ProvinceStateMultiSelect
            label="Origins"
            value={filterState.origins}
            onChange={(value) => onFilterUpdate('origins', value)}
            customOptions={datasetFilters?.origin_province
              ? provinceStateFiltersToProvinceStateOptions(datasetFilters.origin_province)
              : undefined}
          />
        </Box>
        <Box>
          <ProvinceStateMultiSelect
            label="Destinations"
            value={filterState.destinations}
            onChange={(value) => onFilterUpdate('destinations', value)}
            customOptions={datasetFilters?.destination_province
              ? provinceStateFiltersToProvinceStateOptions(datasetFilters.destination_province)
              : undefined}
          />
        </Box>
        <CurrencyToggle useCAD={useCAD} onUpdate={onCurrencyUpdate} />
      </Stack>
    </Box>
  );
}
