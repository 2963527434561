import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { useFilteredViz } from 'hooks';
import { CHART_DEFAULT_HEIGHT, CHART_DEFAULT_WIDTH } from '../constants';
import { AveragePricePerBookedLaneBarChart } from './AveragePricePerBookedLaneBarChart';

interface Props {
  filterState: VizFilterState;
  useCAD: boolean;
  containerWidth: number | undefined;
}

export function AveragePricePerBookedLane(props: Props) {
  const {
    filterState, useCAD, containerWidth,
  } = props;
  const {
    error, vizData,
  } = useFilteredViz<AveragePricePerLaneResponse>('avg-price-per-booked-lane', filterState);

  if (!vizData?.usd || !vizData?.cad) {
    return <div />;
  }

  const vizContainerHeight = CHART_DEFAULT_HEIGHT;
  const vizHeight = CHART_DEFAULT_HEIGHT * 0.9;
  const vizWidth = containerWidth ? containerWidth * 0.6 : CHART_DEFAULT_WIDTH;

  return (
    <Stack
      id="average-price-per-booked-lane"
      direction="column"
      height={vizContainerHeight}
    >
      <Typography variant="h5" textAlign="center">Average Booked Price by Lane</Typography>
      <ErrorTypography error={error} />
      <AveragePricePerBookedLaneBarChart
        data={useCAD ? vizData.cad : vizData.usd}
        useCAD={useCAD}
        width={vizWidth}
        height={vizHeight}
      />
    </Stack>
  );
}
