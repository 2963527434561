import BuildIcon from '@mui/icons-material/Build';
import {
  Button, CircularProgress, Stack,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { useStripeCustomerBillingPortal, useSubscriptionStatus } from 'hooks';

export function ManageSubscriptionButton() {
  const {
    loading,
    error,
    requestCustomerBillingPortalSession,
  } = useStripeCustomerBillingPortal({
    onSessionCreatedSuccess: (url) => {
      window.open(url, '_self');
    },
  });

  const { subscription } = useSubscriptionStatus();

  const onButtonClicked = () => {
    requestCustomerBillingPortalSession();
  };

  if (!subscription) {
    return <div />;
  }

  if (subscription.subscription_plan === 'CARRIER_ESSENTIALS'
    || subscription.subscription_plan === 'SHIPPER_TRIAL'
  || subscription.subscription_plan === 'SHIPPER_PREMIUM_OFF_STRIPE') {
    return <div />;
  }

  return (
    <Stack direction="column">
      <Button
        variant="contained"
        size="small"
        onClick={onButtonClicked}
        endIcon={loading ? <CircularProgress /> : <BuildIcon />}
        disabled={loading}
      >
        {subscription.subscription_plan === 'CARRIER_PAY_PER_BOOKING' ? 'Manage Billing' : 'Manage Subscription'}
      </Button>
      <ErrorTypography error={error} />
    </Stack>

  );
}
