import { Stack } from '@mui/material';
import { useRoleContext } from 'hooks';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { MeanPriceOverTime } from '../Viz';
import { AveragePricePerBookedLane } from '../Viz/AveragePricePerBookedLane';
import { TopBookedLanes } from '../Viz/TopBookedLanes';
import { TotalBookingsPerCarrier } from '../Viz/TotalBookingsPerCarrier';
import { TotalSpendPerCarrier } from '../Viz/TotalSpendPerCarrier';
import { DataFreshnessLabel } from './components/DataFreshnessLabel';
import { DataPageFilters } from './components/DataPageFilters';
import { VizContainer } from './components/VizContainer';

const INITIAL_FILTER_STATE: VizFilterState = {
  equipmentTypes: [],
  services: [],
  origins: [],
  destinations: [],
};

const VIZ_CONTAINER_ID = 'viz-container';

export function DataPage() {
  const { role } = useRoleContext();
  const [filterState, setFilterState] = useState<VizFilterState>(INITIAL_FILTER_STATE);
  const [useCAD, setUseCad] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dimensions, setDimensions] = useState({ // @ts-ignore
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  const handleValueChange = (key: keyof VizFilterState, value: string | string[]): void => {
    setFilterState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  if (role == null || role.type !== 'SHIPPER') {
    return <div />;
  }

  const getCliendWidth = () => document.getElementById(VIZ_CONTAINER_ID)?.clientWidth;
  return (
    <PageLayout>
      <Stack direction="column">
        <Stack direction="column" justifyContent="center" alignItems="center">
          <DataFreshnessLabel />
          <DataPageFilters
            onFilterUpdate={handleValueChange}
            filterState={filterState}
            useCAD={useCAD}
            onCurrencyUpdate={(cad: boolean) => setUseCad(cad)}
          />
        </Stack>
        <Stack
          id={VIZ_CONTAINER_ID}
          direction="column"
          boxShadow={1}
          p="2rem"
          borderRadius={4}
          spacing={2}
          flexWrap="wrap"
          justifyContent="left"
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            flexWrap="wrap"
          >
            <VizContainer>
              <TopBookedLanes
                filterState={filterState}
                containerWidth={getCliendWidth()}
              />
            </VizContainer>
            <VizContainer>
              <AveragePricePerBookedLane
                filterState={filterState}
                useCAD={useCAD}
                containerWidth={getCliendWidth()}
              />
            </VizContainer>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
          >
            <VizContainer>
              <MeanPriceOverTime
                filterState={filterState}
                useCAD={useCAD}
                containerWidth={getCliendWidth()}
              />
            </VizContainer>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            flexWrap="wrap"
          >
            <VizContainer>
              <TotalSpendPerCarrier
                filterState={filterState}
                useCAD={useCAD}
                containerWidth={getCliendWidth()}
              />
            </VizContainer>
            <VizContainer>
              <TotalBookingsPerCarrier
                filterState={filterState}
                containerWidth={getCliendWidth()}
              />
            </VizContainer>
          </Stack>
        </Stack>
      </Stack>
    </PageLayout>
  );
}
