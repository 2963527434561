import { Slider } from '@mui/material';

interface Props {
  value: number;
  max: number;
  onUpdate: (newValue: number) => void;
}

export function DatasetSlider(props: Props) {
  const { value, onUpdate, max } = props;

  const handleItemNbChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      return;
    }
    onUpdate(newValue);
  };

  return (
    <Slider
      value={value}
      onChange={handleItemNbChange}
      valueLabelDisplay="auto"
      min={1}
      max={max}
      aria-labelledby="input-item-number"
    />
  );
}
