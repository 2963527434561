import { Stack } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useDatasetSliderState } from 'hooks';
import { DatasetSlider } from '../DatasetSlider';
import { NoDataFoundForFiltersTypography } from '../NoDataFoundForFiltersTypography';
import { numericDataPointValueFormatter } from '../utilts';

interface Props {
  data: TotalSpendPerCarrier[];
  useCAD: boolean;
  width: number;
  height: number;
}

export function TotalSpendPerCarrierBarChart(props: Props) {
  const {
    data, useCAD, width, height,
  } = props;
  const { sliderValue, setValue } = useDatasetSliderState(data, 3);
  if (data.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }

  const currencySymbol = useCAD ? 'CAD' : 'USD';

  const sortedDataBySpendAmount = data.sort((
    a: TotalSpendPerCarrier,
    b: TotalSpendPerCarrier,
  ) => b.total_spend - a.total_spend).slice(0, sliderValue);

  const companies = sortedDataBySpendAmount.map((totalSpend: TotalSpendPerCarrier) => `${totalSpend.carrier_company_name || ''}`);
  const totalSpendPerCarrier = sortedDataBySpendAmount.map(
    (totalSpend: TotalSpendPerCarrier) => totalSpend.total_spend,
  );

  return (
    <Stack
      direction="column"
    >
      <BarChart
        xAxis={[{ scaleType: 'band', data: companies }]}
        series={[{ data: totalSpendPerCarrier, valueFormatter: (val) => `Total: $${numericDataPointValueFormatter(val)} ${currencySymbol}` }]}
        width={width}
        height={height}
        yAxis={[{ valueFormatter: (val) => `$${val}` }]}
      />
      <DatasetSlider
        value={sliderValue}
        onUpdate={(val) => setValue(val)}
        max={data.length}
      />

    </Stack>

  );
}
