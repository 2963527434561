export const TERMS_OF_SERVICE_URL = 'https://evotrux.com/terms';
export const BG_ICON_COLOR = '#0d2e45';
export const METER_MILE_CONVERSION_FACTOR = 1609.344;

/*
Orders Quote states by relevance to the user.
The higher the index in the list, the higher the importance
For example, for a shipment with mulitple quotes, an accepted
quote will be at the top of the list of quotes.

To sort quotes with priority values use orderQuotesByQuoteStatus()
in frontend/src/utils/index.ts
*/
export const QUOTE_STATUS_PRIORITY_LIST = [
  'ACCEPTED',
  'ACTIVE',
  'COUNTERED',
  'CANCELLED',
  'EXPIRED',
  'SHIPMENT_LOST',
  'SHIPMENT_CANCELLED',
  'SHIPMENT_EXPIRED',
];

export const SUBSCRIPTION_PRODUCT = {
  CARRIER_ESSENTIAL: 'CARRIER_ESSENTIAL',
  CARRIER_OWNER_OPERATOR: 'CARRIER_OWNER_OPERATOR',
  CARRIER_SMALL_BUSINESS: 'CARRIER_SMALL_BUSINESS',
  CARRIER_ENTERPRISE: 'CARRIER_ENTERPRISE',
  CARRIER_ENTERPRISE_ADDITIONAL_SEAT: 'CARRIER_ENTERPRISE_ADDITIONAL_SEAT',
  SHIPPER: 'SHIPPER',
  CARRIER_BRONZE: 'CARRIER_BRONZE',
  CARRIER_SILVER: 'CARRIER_SILVER',
  CARRIER_GOLD: 'CARRIER_GOLD',
  CARRIER_PLATINUM: 'CARRIER_PLATINUM',
  CARRIER_ENTERPRISE_2: 'CARRIER_ENTERPRISE_2',
};

export const ROLE_TYPE = {
  CARRIER: 'CARRIER',
  SHIPPER: 'SHIPPER',
};

export const PAYMENT_PLAN_TYPE = {
  SHIPPER_PAY_PER_POSTING: 'SHIPPER_PAY_PER_POSTING',
  SHIPPER_SUBSCRIPTION: 'SHIPPER_SUBSCRIPTION',
};

export const QUOTES_ELEMENT_ID = 'quotes-element-id';

export const EVOTRUX_BLUE = '#1976d2';

export const CARRIER_SUBSCRIPTION_PRODUCT_TO_NUMBER_OF_BOOKINGS_MAP = {
  [SUBSCRIPTION_PRODUCT.CARRIER_BRONZE]: '5',
  [SUBSCRIPTION_PRODUCT.CARRIER_SILVER]: '10',
  [SUBSCRIPTION_PRODUCT.CARRIER_GOLD]: '20',
  [SUBSCRIPTION_PRODUCT.CARRIER_PLATINUM]: '40',
  [SUBSCRIPTION_PRODUCT.CARRIER_ENTERPRISE_2]: 'Unlimited',
};

export const CARRIER_SUBSCRIPTION_PRODUCT_TO_SHIPPER_NETWORK_SIZE_MAP = {
  [SUBSCRIPTION_PRODUCT.CARRIER_BRONZE]: '3',
  [SUBSCRIPTION_PRODUCT.CARRIER_SILVER]: '5',
  [SUBSCRIPTION_PRODUCT.CARRIER_GOLD]: '7',
  [SUBSCRIPTION_PRODUCT.CARRIER_PLATINUM]: '10',
  [SUBSCRIPTION_PRODUCT.CARRIER_ENTERPRISE_2]: 'Unlimited',
};

export const CARRIER_SUBSCRIPTION_PRODUCT_TO_PRODUCT_USER_FRIENDLY_NAME = {
  [SUBSCRIPTION_PRODUCT.CARRIER_BRONZE]: 'Bronze',
  [SUBSCRIPTION_PRODUCT.CARRIER_SILVER]: 'Silver',
  [SUBSCRIPTION_PRODUCT.CARRIER_GOLD]: 'Gold',
  [SUBSCRIPTION_PRODUCT.CARRIER_PLATINUM]: 'Platinum',
  [SUBSCRIPTION_PRODUCT.CARRIER_ENTERPRISE_2]: 'Enterprise',
};

export const CARRIER_SUBSCRIPTION_PRODUCT_PLUS_INTERVAL_TO_ORDERING_MAP = {
  [SUBSCRIPTION_PRODUCT.CARRIER_BRONZE]: 1,
  [SUBSCRIPTION_PRODUCT.CARRIER_SILVER]: 2,
  [SUBSCRIPTION_PRODUCT.CARRIER_GOLD]: 3,
  [SUBSCRIPTION_PRODUCT.CARRIER_PLATINUM]: 4,
  [SUBSCRIPTION_PRODUCT.CARRIER_ENTERPRISE_2]: 5,
};

export const CARRIER_UPGRADE_RELATIVE_URL = '/carrier/upgrade';
export const SHIPPER_UPGRADE_RELATIVE_URL = '/shipper/upgrade';

export const MINIMUM_QUOTES_ON_SHIPMENT_TO_DISPLAY_BOOKED_PRICE = 4;

type ProvinceStateValueToLabelMap = { [key: string]: string };

export const PROVINCE_STATE_VALUE_TO_LABEL_MAP: ProvinceStateValueToLabelMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AB: 'Alberta',
  AZ: 'Arizona',
  AR: 'Arkansas',
  BC: 'British Columbia',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MB: 'Manitoba',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NB: 'New Brunswick',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NL: 'Newfoundland and Labrador',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  OH: 'Ohio',
  OK: 'Oklahoma',
  ON: 'Ontario',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  RI: 'Rhode Island',
  SK: 'Saskatchewan',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  YT: 'Yukon',
};

export const PROVINCE_STATE_LIST = Object.keys(PROVINCE_STATE_VALUE_TO_LABEL_MAP).map((value) => ({
  value,
  label: PROVINCE_STATE_VALUE_TO_LABEL_MAP[value],
}));

type ShipmentServiceValueToLabelMap = { [key: string]: string };

export const SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP: ShipmentServiceValueToLabelMap = {
  TRUCK_LOAD: 'Full Load (FTL/FCL)',
  LESS_THAN_TRUCK_LOAD: 'Partial Load (LTL/LCL)',
};

export const getServiceTypeDisplayLabel = (serviceType:
string) => SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP[serviceType]
|| serviceType;

export const SHIPMENT_SERVICE_OPTIONS = Object.keys(
  SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP,
).map((value) => ({
  value,
  label: SHIPMENT_SERVICE_VALUE_TO_LABEL_MAP[value],
}));

type EquipmentValueToLabelMap = { [key: string]: string };

export const EQUIPMENT_VALUE_TO_LABEL_MAP: EquipmentValueToLabelMap = {
  DRY_VAN: 'Dry Van',
  REEFER: 'Reefer',
  FLAT_DECK: 'Flat Deck',
  STEP_DECK_DROP_DECK: 'Step Deck/Drop Deck',
  AUTO_HAULER_CAR_CARRIER: 'Auto-Hauler/Car-Carrier',
  BOTTOM_SIDE_DUMP: 'Bottom/Side Dump',
  CONESTOGA_ROLL_TITE: 'Conestoga/Roll-Tite',
  DOUBLE_DROP_LOWBOY: 'Double Drop/Lowboy',
  DRY_BULK_HOPPER: 'Dry Bulk (Hopper)',
  DRY_BULK_SUPER_B_HOPPER: 'Dry Bulk Super-B (Hopper)',
  DUMP_BODY_TRANSFER_BOX: 'Dump Body/Transfer Box',
  DUMP_TRUCK: 'Dump Truck',
  HEAVY_HAUL_OVER_DIMENSIONAL: 'Heavy Haul/Over dimensional',
  HOTSHOT: 'Hotshot',
  INTERMODAL_CHASSIS: 'Intermodal/Chassis',
  LIQUID_BULK_TANKER: 'Liquid Bulk (Tanker)',
  LIQUID_BULK_SUPER_B_TANKER: 'Liquid Bulk Super-B (Tanker)',
  LIVESTOCK: 'Livestock',
  LOGGING: 'Logging',
  OTHER: 'Other',
  POLE_TRAILER: 'Pole Trailer',
  RGN: 'RGN (Removable Gooseneck)',
  SIDE_KIT: 'Side Kit',
  SPRINTER_VAN: 'Sprinter Van',
  STRAIGHT_TRUCK: 'Straight Truck',
  SUPER_B: 'Super-B',
  TROMBONE_TRAILER: 'Trombone Trailer',
  POWER_ONLY: 'Power only',
  OCEAN_CONTAINER_40_FT: "40' Ocean Container",
  OCEAN_CONTAINER_20_FT: "20' Ocean Container",
};

export const DEFAULT_EQUIPMENT_TYPE_OPTIONS = Object.keys(
  EQUIPMENT_VALUE_TO_LABEL_MAP,
).map((value) => ({
  value,
  label: EQUIPMENT_VALUE_TO_LABEL_MAP[value],
}));

export const TAWK_TO_PROPERTY_ID = '64026ac031ebfa0fe7f08e71';
export const TAWK_TO_WIDGET_ID = '1gqkn206s';
export const DEVELOPER_DOCS_URL = 'https://developers.evotrux.com';
export const SHIPMENT_TRACKING_LINK_BLOG_URL = 'https://www.evotrux.com/blog/tracking-links-on-evotrux';
export const CANCELLATION_REASON_LINK_BLOG_URL = 'https://www.evotrux.com/blog/tracking-links-on-evotrux';

export const WHAT_ARE_MUTLI_SHIPMENT_PROJECTS_BLOG_URL = 'https://evotrux.tawk.help/article/what-are-multi-shipment-projects';
export const QUOTING_MUTLI_SHIPMENT_PROJECTS_BLOG_URL = 'https://evotrux.tawk.help/article/quoting-multi-shipment-projects';
export const MUTLI_SHIPMENT_PROJECTS_BILLING_FOR_CARRIERS_BLOG_URL = 'https://evotrux.tawk.help/article/how-non-partner-multi-shipment-project-bookings-are-billed-for-carriers';

export const FULLY_SUPPORT_COUNTRIES = new Set(['CA', 'US', 'MX']);

export const LIMITED_BOOKING_CARRIERS_SUBSCRIPTION_PLANS: Set<SubscriptionPlan> = new Set
<SubscriptionPlan>([
  'CARRIER_ESSENTIALS',
  'CARRIER_BRONZE',
  'CARRIER_SILVER',
  'CARRIER_GOLD',
  // 'CARRIER_ENTERPRISE_2', unlimited
]);

export const LIMITED_POSTINGS_SHIPPER_SUBSCRIPTION_PLANS: Set<SubscriptionPlan> = new Set
<SubscriptionPlan>([
  'SHIPPER_SHIP_A',
  'SHIPPER_SHIP_B',
  // 'SHIPPER_SHIP_C', unlimited
  'SHIPPER_OPTIMIZE_A',
  'SHIPPER_OPTIMIZE_B',
  // 'SHIPPER_PREMIUM', unlimited
  // 'SHIPPER_PREMIUM_OFF_STRIPE',unlimited
]);
