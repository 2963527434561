import { LineChart } from '@mui/x-charts/LineChart';
import { getMonthFromUnixEpoch } from '../../../../../utils';
import { NoDataFoundForFiltersTypography } from '../NoDataFoundForFiltersTypography';
import { numericDataPointValueFormatter } from '../utilts';

interface Props {
  result: PriceOverMonthVizResponse | null,
  useCAD: boolean,
  width: number;
  height: number;
}

export function MeanPriceOverTimeGraph(props: Props) {
  const {
    result, useCAD, width, height,
  } = props;

  if (result == null) {
    return <div />;
  }

  // empty dataset breaks the graphing component, return something else if no results
  if (result.cad.monthly_mean_booked_and_asked.booked_and_asked_over_time.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }

  let dataset = [];
  if (useCAD) {
    dataset = result.cad.monthly_mean_booked_and_asked.booked_and_asked_over_time;
  } else {
    dataset = result.usd.monthly_mean_booked_and_asked.booked_and_asked_over_time;
  }

  let currencySymbol = 'USD';
  if (useCAD) {
    currencySymbol = 'CAD';
  }

  const datapointsWithBookingData = dataset.filter((val: MeanPriceOverTime) => val.booked > 0);

  if (datapointsWithBookingData.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }

  const dates = datapointsWithBookingData.map((val: MeanPriceOverTime) => val.period);

  const months = dates.map((date) => getMonthFromUnixEpoch(date));
  const asked = datapointsWithBookingData.map((val: MeanPriceOverTime) => val.asked);
  const booked = datapointsWithBookingData.map((val: MeanPriceOverTime) => val.booked);

  return (
    <LineChart
      xAxis={[{ data: months, scaleType: 'band' }]}
      series={[
        {
          data: booked,
          label: 'Booked',
          showMark: true,
          color: '#4e79a7',
          valueFormatter: (val: number) => `$${numericDataPointValueFormatter(val)} ${currencySymbol}`,
        },
        {
          data: asked,
          label: 'Asked',
          showMark: true,
          color: '#59a14f',
          valueFormatter: (val: number) => `$${numericDataPointValueFormatter(val)} ${currencySymbol}`,
        },
      ]}
      yAxis={[{ valueFormatter: (val) => `$${val}` }]}
      width={width}
      height={height}
    />
  );
}
