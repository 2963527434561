import { Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDatasetSliderState } from 'hooks';
import { DatasetSlider } from '../DatasetSlider';
import { NoDataFoundForFiltersTypography } from '../NoDataFoundForFiltersTypography';

interface Props {
  data: LaneIncidence[]
  width: number;
  height: number;
}

export function TopBookedLanesPieChart(props: Props) {
  const { data, width, height } = props;
  const {
    sliderValue: laneDisplayCount,
    setValue: setLaneDisplayCount,
  } = useDatasetSliderState(data, 3);

  if (data.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }
  const formattedData = data.map(
    (value: LaneIncidence, index: number) => ({
      id: index,
      label: `${value.origin}-${value.destination}`,
      value: value.count,
    }),
  ).sort((a, b) => (b.value - a.value)).slice(0, laneDisplayCount);

  return (
    <Stack
      direction="column"
      width={width}
      height={height}

    >
      <PieChart
        series={[
          {
            data: formattedData,
            valueFormatter: (val) => `${val.value} bookings`,
            highlightScope: { faded: 'global', highlighted: 'item' },
          },
        ]}

      />
      <Typography id="input-item-number" gutterBottom>
        Showing top
        {' '}
        {laneDisplayCount}
        {' '}
        booked lanes
      </Typography>
      <DatasetSlider
        value={laneDisplayCount}
        onUpdate={(val) => setLaneDisplayCount(val)}
        max={data.length}
      />
    </Stack>
  );
}
