import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

interface HasExploAccessResponse {
  has_access: boolean;
}

export function useExploAccess() {
  const { role } = useRoleContext();
  const [hasAccess, setHasAccess] = useState<HasExploAccessResponse | null>(null);
  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<HasExploAccessResponse>({
    errorMessage: 'Failed to fetch explo access details',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/viz/has-explo-access` : '/',
    },
  });

  useEffect(() => {
    if (role != null && role.type === 'SHIPPER' && !success) {
      fetch((res) => {
        setHasAccess(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    hasAccess: hasAccess !== null ? hasAccess.has_access : null,
  };
}
