import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box, Stack, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { MultiShipmentChildShipmentChip } from 'components/MultiShipmentChildShipmentChip';
import { PrivateShipmentChip } from 'components/PrivateShipmentChip';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { ShipmentCopyModal } from 'components/ShipmentCopy/';
import { RouteTab } from 'components/ShipmentOverviewTab/components/RouteTab';
import { ShipmentQATab } from 'components/ShipmentOverviewTab/components/ShipmentQATab';
import { WhatAreMultiShipmentProjectsAlert } from 'components/WhatAreMultiShipmentProjectsAlert';
import { useShipperShipmentPageContext } from 'hooks';
import { useState } from 'react';
import { QUOTES_ELEMENT_ID } from '../../../../constants';
import { ISOStringToReadableDate } from '../../../../utils';
import { ShipmentDetails } from '../../../Shipment/components/ShipmentDetails';
import { ShipmentStatusBadge } from '../../../ShipmentStatusBadge';
import { EditableShipmentDetailTitle } from '../EditableShipmentDetailTitle';
import { SharedShipmentChip } from '../SharedShipmentChip';
import { ShipmentProgress } from '../ShipmentProgress';
import { ShipperQuoteTab } from '../ShipperQuoteTab';
import {
  ShipmentTrackingLinkSection,
  ShipperShipmentOverviewChat,
} from './components';
import { ChildShipmentProjectBookingsSection } from './components/ChildShipmentProjectBookingsSection';
import { MultiShipmentProjectBookingsSection } from './components/MultiShipmentProjectBookingsSection';
import { ShipmentCancellationSection } from './components/ShipmentCancellationSection';
import { SideBarMenu } from './components/SideBarMenu';

interface Props {
  shipment: ShipperCondensedShipment | undefined;
  onOpenCancelShipment: VoidFunction;
  shipmentCopyFormOpen: boolean;
  onShipmentCopyClose: VoidFunction;
  onShipmentCopyOpen: VoidFunction;
  onCreateTemplateFromShipmentOpen: VoidFunction;
  onUpdateEmailNotificationSettingsOpen: VoidFunction;
  onShareShipmentWithTeammatesOpen: VoidFunction;
}

export function ShipmentOverviewExpanded(props: Props) {
  const {
    shipment,
    onOpenCancelShipment,
    shipmentCopyFormOpen,
    onShipmentCopyClose,
    onShipmentCopyOpen,
    onCreateTemplateFromShipmentOpen,
    onUpdateEmailNotificationSettingsOpen,
    onShareShipmentWithTeammatesOpen,
  } = props;
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { onRefresh } = useShipperShipmentPageContext();

  const sectionHeaderType = 'h5';

  const SECTION_SPACE = '4rem';
  const useColumnLayout = useMediaQuery('(max-width:800px)');

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getShipmentSubheader = () => {
    let dateText = null;
    if (shipment?.info?.created_at) {
      dateText = ISOStringToReadableDate(shipment.info.created_at);
    }
    if (shipment && shipment.access_type === 'OWNER') {
      return `Posted by you ${dateText ?? '-'}`;
    }
    if (shipment && shipment.access_type === 'SHAREE_READ_AND_WRITE') {
      return `Posted by ${shipment?.shipment_creator?.first_name || ''} ${
        shipment?.shipment_creator?.last_name || ''
      } on ${dateText ?? '-'}`;
    }
    return '';
  };

  return (
    <Box boxShadow={1}>
      <Box p="2rem">
        <Stack
          mb="1.5rem"
          id="header-stack"
          direction={useColumnLayout ? 'column' : 'row'}
          alignItems="baseline"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box
            id="shipment-title-and-status"
            width={useColumnLayout ? undefined : '23%'}
          >
            <Stack id="shipment-date-status" direction="column">
              <EditableShipmentDetailTitle shipment={shipment} />

              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2" color="text.secondary">
                  {getShipmentSubheader()}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ShipmentStatusBadge
                  status={shipment?.shipment_status}
                  size="small"
                />
                {shipment?.is_private && (
                  <PrivateShipmentChip
                    isPrivate={shipment?.is_private ?? false}
                    isProject={shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT'}
                  />
                )}
                {shipment && (
                  <SharedShipmentChip shipment={shipment} size="small" />
                )}
                {
                  shipment && (
                  <MultiShipmentChildShipmentChip isMultiShipmentChildShipment={shipment?.info.type === 'MULTI_SHIPMENT_PROJECT_CHILD'} />
                  )
                }
                <RefreshPageButton
                  id="shipper-shipment-overview-refresh"
                  iconButton
                  hardReload={false}
                  onRefresh={onRefresh || (() => {})}
                />
              </Stack>
            </Stack>
          </Box>
          <Box
            id="shipment-progres"
            width={useColumnLayout ? undefined : '65%'}
            ml="1rem"
          >
            <ShipmentProgress shipment={shipment} />
          </Box>
          <Box
            id="submit-quote-half"
            width={useColumnLayout ? undefined : '10%'}
          >
            <Stack
              id="button-stack"
              justifyContent="end"
              direction="row"
              spacing={5}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(drawerOpen && { display: 'none' }) }}
              >
                <MenuIcon />
                {useColumnLayout && <Typography>Shipment Options</Typography>}
              </IconButton>
            </Stack>
          </Box>
        </Stack>
        <SideBarMenu
          open={drawerOpen}
          handleDrawerClose={handleDrawerClose}
          onShareShipmentWithTeammatesOpen={onShareShipmentWithTeammatesOpen}
          onShipmentCopyOpen={onShipmentCopyOpen}
          onOpenCancelShipment={onOpenCancelShipment}
          onCreateTemplateFromShipmentOpen={onCreateTemplateFromShipmentOpen}
          onUpdateEmailNotificationSettingsOpen={
            onUpdateEmailNotificationSettingsOpen
          }
        />
        <ShipmentCancellationSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant={sectionHeaderType}>
                  Shipment Cancellation Reason
                </Typography>
              </Stack>
              <EvotruxDivider />
            </>
          )}
        />
        <ShipmentTrackingLinkSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant={sectionHeaderType}>
                  Shipment Tracking
                </Typography>
                <Tooltip
                  title={(
                    <Typography variant="h5">
                      Manage your booked shipment&apos;s tracking here.
                    </Typography>
                  )}
                >
                  <InfoOutlinedIcon color="primary" fontSize="large" />
                </Tooltip>
              </Stack>
              <EvotruxDivider />
            </>
          )}
        />
        {shipment
        && shipment?.shipment_status === 'BOOKED'
         && shipment?.info.type !== 'MULTI_SHIPMENT_PROJECT' && (
         <ShipperShipmentOverviewChat
           dividerComponent={(
             <>
               <Stack direction="row" alignItems="center" spacing={1}>
                 <Typography variant={sectionHeaderType}>Live Chat</Typography>
                 <Tooltip
                   title={(
                     <Typography variant="h5">
                       Speak to the carrier in real time here.
                     </Typography>
                    )}
                 >
                   <InfoOutlinedIcon color="primary" fontSize="large" />
                 </Tooltip>
               </Stack>
               <EvotruxDivider />
             </>
            )}
         />
        )}
        <MultiShipmentProjectBookingsSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant={sectionHeaderType}>
                  Project Bookings
                </Typography>
                <Tooltip
                  title={(
                    <Typography variant="h5">
                      Shipments booked for your project will collect here.
                    </Typography>
                  )}
                >
                  <InfoOutlinedIcon color="primary" fontSize="large" />
                </Tooltip>
              </Stack>
              <EvotruxDivider />
            </>
          )}
          parentShipmentId={shipment?.project?.project}
        />
        <ChildShipmentProjectBookingsSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1} mt="1rem">
                <Typography variant={sectionHeaderType}>
                  Related Project Bookings
                </Typography>
                <Tooltip
                  title={(
                    <Typography variant="h5">
                      The rest of bookings made for this project.
                    </Typography>
                  )}
                >
                  <InfoOutlinedIcon color="primary" fontSize="large" />
                </Tooltip>
              </Stack>
              <EvotruxDivider />
            </>
          )}
          parentShipmentId={shipment?.project?.project}
        />
        <Stack
          id={QUOTES_ELEMENT_ID}
          direction="row"
          mt="2rem"
          alignItems="center"
          spacing={1}
        >
          <Typography variant={sectionHeaderType}>Quotes</Typography>
          <Tooltip
            title={(
              <Typography variant="h5">
                This is where quotes appear from carriers trying to win your
                shipment. All quotes are all-in + taxes unless explicitly
                indicated by the carrier.
              </Typography>
            )}
          >
            <InfoOutlinedIcon color="primary" fontSize="large" />
          </Tooltip>
        </Stack>
        <EvotruxDivider />
        <ShipperQuoteTab shipment={shipment} />

        <Stack
          direction="row"
          mt={SECTION_SPACE}
          alignItems="center"
          spacing={1}
        >
          <Typography variant={sectionHeaderType}>
            Questions & Answers
          </Typography>
          <Tooltip
            title={(
              <Typography variant="h5">
                Carriers can ask questions about your load here and you can
                answer them. All questions and answers are publicly visible. Do
                not discuss rates or send contact information here.
              </Typography>
            )}
          >
            <InfoOutlinedIcon color="primary" fontSize="large" />
          </Tooltip>
        </Stack>
        <EvotruxDivider />

        <ShipmentQATab shipment={shipment} />

        <Stack
          direction="row"
          mt={SECTION_SPACE}
          alignItems="center"
          spacing={1}
        >
          <Typography variant={sectionHeaderType}>Shipment Details</Typography>
        </Stack>
        <EvotruxDivider />
        <WhatAreMultiShipmentProjectsAlert shipment={shipment} />
        <ShipmentDetails shipment={shipment} />

        <Stack
          direction="row"
          mt={SECTION_SPACE}
          alignItems="center"
          spacing={1}
        >
          <Typography variant={sectionHeaderType}>Route</Typography>
        </Stack>
        <EvotruxDivider />

        <RouteTab shipment={shipment} />
      </Box>

      {shipmentCopyFormOpen && (
        <ShipmentCopyModal
          onShipmentCopyClose={onShipmentCopyClose}
          shipment={shipment}
        />
      )}
    </Box>
  );
}
