import { Typography, useMediaQuery } from '@mui/material';
import { ShipperUpgradeNowButton } from 'components/Shipper/components/ShipperUpgradeNowButton/ShipperUpgradeNowButton';
import { useSubscriptionStatus } from 'hooks';
import { SHIPPER_UPGRADE_RELATIVE_URL } from '../../../../constants';
import { BannerStack } from '../BannerStack';
import { CompanyProfileBanner } from '../CompanyProfileBanner';
import { ShipperTrialDaysRemainingText } from '../ShipperTrialDaysRemainingText';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function TrialShipperBanner(props: Props) {
  const { showNavBar, navBar } = props;
  const { subscription } = useSubscriptionStatus();
  const showText = useMediaQuery('(min-width:900px)');

  let textComponent = <ShipperTrialDaysRemainingText />;
  if (subscription?.subscription_plan === 'SHIPPER_TRIAL' && !subscription.is_active) {
    textComponent = (
      <Typography variant="h6" color="white">
        {'Your trial has ended. '}
        <span style={{ color: 'yellow' }}>
          <a style={{ textDecoration: 'none', color: 'yellow' }} href={SHIPPER_UPGRADE_RELATIVE_URL}>Upgrade now</a>
        </span>
        {' to continue using Evotrux!'}
      </Typography>
    );
  }

  return (
    <BannerStack
      borderRadius={3}
      showNavBar={showNavBar}
      navBar={navBar}
    >
      <CompanyProfileBanner />
      {showText && textComponent}
      <ShipperUpgradeNowButton buttonSize="large" />
    </BannerStack>
  );
}
