import LaunchIcon from '@mui/icons-material/Launch';
import { Button } from '@mui/material';
import { useRoleContext, useUserChatInfo } from 'hooks';
import { useEffect, useState } from 'react';
import { getShipmentBookedChatUrl, getShipmentIdFromShipmentBookedChatName } from 'utils';

interface Props {
  shipmentId: number;
}

export function JumpToChatButton(props: Props) {
  const { shipmentId } = props;
  const { role } = useRoleContext();
  const { chatInfo } = useUserChatInfo();

  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldDisplay && chatInfo) {
      // eslint-disable-next-line
      chatInfo.channels.map((channel: Channel) => {
        if (parseInt(getShipmentIdFromShipmentBookedChatName(channel.name), 10) === shipmentId) {
          setShouldDisplay(true);
        }
      });
    }
  }, [chatInfo, shouldDisplay, shipmentId]);

  useEffect(() => {
    setShouldDisplay(false);
  }, [shipmentId, setShouldDisplay]);

  if (role == null || !shouldDisplay) {
    return <div />;
  }

  const speakToRole = role.type === 'CARRIER' ? 'SHIPPER' : 'CARRIER';
  return (
    <Button
      variant="contained"
      onClick={() => window.open(getShipmentBookedChatUrl(shipmentId), '_blank')}
      endIcon={<LaunchIcon />}
    >
      CHAT WITH
      {' '}
      {speakToRole}
    </Button>
  );
}
