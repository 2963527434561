import { useRoleContext } from 'hooks';
import { ExploDashboard } from '../ExploDashboard';

export function CompanyAdminDashboard() {
  const { role, getAdminRole } = useRoleContext();

  if (role === null || role.type !== 'SHIPPER' || getAdminRole() == null) {
    return <div />;
  }

  const endpoint = `/shippers/${role.id}/viz/company-admin-dashboard`;
  return <ExploDashboard endpoint={endpoint} />;
}
