import { MessageEnvelope, MessagePayload } from '@pubnub/react-chat-components';
import { useAuthContext, useChannelAPI } from 'hooks';
import { usePubNub } from 'pubnub-react';
import { getShipmentIdFromShipmentBookedChatName, messageSentCallbackValueIsFile } from 'utils';
import { ChatConversation } from './ChatConversation';
import { ChatConversationHeader } from './components/ChatConversationHeader';

interface Props {
  currentChannel: string;
  channelGroup: UserChannelGroup;
  userPubnubData: PubnubClientData;
  onNewMessage?: (channelName: string | undefined) => void;
  useDesktopLayout: boolean;
  isStandaloneChat?: boolean;
}

export function ChatConversationContainer(props: Props) {
  const {
    currentChannel,
    channelGroup,
    userPubnubData,
    onNewMessage: parentOnNewMessage,
    useDesktopLayout,
    isStandaloneChat,
  } = props;
  const { user } = useAuthContext();
  const { updateTimeTokenForChannel } = useChannelAPI();
  const pubnubClient = usePubNub();

  if (user == null) {
    return <p>Something went wrong</p>;
  }

  const onMessageSent = (value: MessagePayload | File | unknown): void => {
    /*
    Had to use unknown instead of UriFileInput because for some reason
    '@pubnub/react-chat-components' does not export the UriFileInput interface
    */
    if (messageSentCallbackValueIsFile(value)) {
      /*
        Due to a bug in the message fetching, everytime a file is shared,
        the file is only downloadable after a subsequent message.
        This function sends a message after a file is sent and also reminds the
        user that files and messages have a set retention period.

        This message has an id because file messages do not get sent into webhooks.s
        Add the user id so that this message triggers an unread notification for the unread file.
      */
      pubnubClient.publish({
        channel: currentChannel,
        message: {
          text: 'File shared. Files and messages have 30 day in-conversation storage.',
          sender: {
            name: 'Evotrux System',
          },
          meta: {
            user_id: `user.${user.id}`,
          },
        },
      });
    }
  };

  const onNewMessage = (message: MessageEnvelope): void => {
    /*
      If the message received was in the same channel as the currentChannel,
      POST the message's timetoken to the backend to mark it as read.
    */
    if (message?.channel && message?.timetoken && message.channel === currentChannel) {
      updateTimeTokenForChannel(message.channel, `${message.timetoken}`);
    }

    if (parentOnNewMessage) {
      parentOnNewMessage(message?.channel);
    }
  };

  return (
    <ChatConversation
      currentChannel={currentChannel}
      channelGroup={channelGroup}
      user={user}
      userPubnubData={userPubnubData}
      onMessageSent={onMessageSent}
      conversationHeader={(
        <ChatConversationHeader
          shipmentId={getShipmentIdFromShipmentBookedChatName(currentChannel)}
        />
      )}
      onNewMessage={onNewMessage}
      useDesktopLayout={useDesktopLayout}
      isStandaloneChat={isStandaloneChat}
    />
  );
}

ChatConversationContainer.defaultProps = {
  onNewMessage: null,
  isStandaloneChat: false,
};
