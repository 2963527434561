import { Stack } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useDatasetSliderState } from 'hooks';
import { DatasetSlider } from '../DatasetSlider';
import { NoDataFoundForFiltersTypography } from '../NoDataFoundForFiltersTypography';

interface Props {
  data: TotalSpendPerCarrier[];
  height: number;
  width: number;
}

export function TotalBookingsPerCarrierBarChart(props: Props) {
  const {
    data, height, width,
  } = props;
  const { sliderValue, setValue } = useDatasetSliderState(data, 3);
  if (data.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }

  const sortedDataByBookingCount = data.sort((
    a: TotalSpendPerCarrier,
    b: TotalSpendPerCarrier,
  ) => b.booking_count - a.booking_count).slice(0, sliderValue);

  const companies = sortedDataByBookingCount.map((totalSpend: TotalSpendPerCarrier) => `${totalSpend.carrier_company_name || ''}`);
  const bookings = sortedDataByBookingCount.map(
    (totalSpend: TotalSpendPerCarrier) => totalSpend.booking_count,
  );

  return (
    <Stack direction="column">
      <BarChart
        xAxis={[{ scaleType: 'band', data: companies }]}
        series={[{ data: bookings, valueFormatter: (val) => `Total: ${val} bookings` }]}
        width={width}
        height={height}
        yAxis={[{ label: '# of bookings' }]}
      />
      <DatasetSlider
        value={sliderValue}
        onUpdate={(val) => setValue(val)}
        max={data.length}
      />
    </Stack>

  );
}
