import {
  MessageEnvelope,
} from '@pubnub/react-chat-components';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';

interface Props {
  channel: string;
}

export function usePubNubChannelHistory(props: Props) {
  const { channel } = props;
  const [messages, setMessages] = useState<MessageEnvelope[] | undefined>([]);
  const pubnubClient = usePubNub();

  useEffect(() => {
    pubnubClient.fetchMessages({
      channels: [channel],
      count: 100,
    }, (status, response) => {
      if (status.error) {
        console.error(status.errorData);
      } else {
        setMessages(response.channels[channel]);
      }
    });
  }, [channel, pubnubClient]);

  return {
    messages,
  };
}
