import { ChannelEntity, UserEntity } from '@pubnub/react-chat-components';

export function getChannelDisplayName(channelName: string) {
  const split = channelName.split('.');
  if (split.length === 3) {
    return `Shipment ${split[2]}`;
  }
  return channelName;
}

export function getChannelDisplayFromChannel(channel: Channel): string {
  return getChannelDisplayName(channel.name);
}

export function getShipmentIdFromShipmentBookedChatName(channelName: string): string {
  const split = channelName.split('.');
  if (split.length === 3) {
    return split[2];
  }
  return '';
}

export function getShipmentIdFromShipmentBookedChat(channel: Channel): string {
  return getShipmentIdFromShipmentBookedChatName(channel.name);
}

export function getChannelEntityFromChannel(channel: Channel): ChannelEntity {
  return {
    name: getChannelDisplayFromChannel(channel),
    description: `Chat for booked shipment ${getShipmentIdFromShipmentBookedChat(channel)}`,
    eTag: '',
    id: channel.name,
    updated: channel.created_at,

  };
}

export function getUserEntity(user: User, pubnubUserInfo: PubnubClientData): UserEntity {
  return {
    id: pubnubUserInfo.user_uuid,
    name: `${user.first_name} ${user.last_name}`,
    // profileUrl: 'https://google.com',

    eTag: pubnubUserInfo.user_uuid,
    /*
    The eTag attribute in the UserEntity can be used to determine if
    the user object has been updated or changed since it was last retrieved.
    You can compare the eTag value returned in the response with the eTag value
    you have stored locally to determine if the user object has been updated.

    User metadata will not be changing often enough to care about mantaining eTag
    */
    updated: user.created_at,
    /*
    the updated attribute in PubNub's UserEntity
    represents the timestamp of when a user object was last updated in PubNub,
    which is useful for tracking changes to user objects over time.
    */
  };
}

export function messageSentCallbackValueIsFile(object: any) {
  return 'type' in object && object.type !== 'default';
}

export function messageSentCallbackValueIsText(object: any) {
  return 'type' in object && object.type === 'default';
}

export function getShipmentBookedChatUrl(shipmentId: number) {
  return `/chat/shipment.booked.${shipmentId}`;
}

function sortDesc(a: number, b: number) {
  if (a < b) {
    return 1;
  } if (a > b) {
    return -1;
  }
  return 0;
}

export function sortChannelEntities(a: ChannelEntity, b:ChannelEntity): 1 | -1 | 0 {
  const aId = parseInt(getShipmentIdFromShipmentBookedChatName(a.id), 10);
  const bId = parseInt(getShipmentIdFromShipmentBookedChatName(b.id), 10);

  return sortDesc(aId, bId);
}

export function sortShipmentBookedChannels(a: Channel, b: Channel): 1 | -1 | 0 {
  const aId = parseInt(getShipmentIdFromShipmentBookedChatName(a.name), 10);
  const bId = parseInt(getShipmentIdFromShipmentBookedChatName(b.name), 10);
  return sortDesc(aId, bId);
}

export function sortShipmentBookedChannelsList(channels: Channel[]) {
  return channels.sort(sortShipmentBookedChannels);
}

export function channelExistsInChatInfo(chatInfo: UserChatInfo, channelName: string) {
  return chatInfo.channels.some((channel) => channel.name === channelName);
}

export function buildBookedShipmentChannelName(shipmentId: number) {
  return `shipment.booked.${shipmentId}`;
}
