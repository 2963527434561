import { useAPI, useDisclosure, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentTemplateMenu } from './ShipmentTemplateMenu';

interface Props {
  onTemplateClick: (template: ShipmentTemplate) => void;
}

export function ShipmentTemplateMenuContainer(props: Props) {
  const { onTemplateClick } = props;
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const { role } = useRoleContext();
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<ShipmentTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [deletedTemplate, setDeletedTemplate] = useState<boolean>(false);

  useEffect(() => {
    if (loading && role?.company) {
      setError(null);
      setIsLoading(true);
      api<ShipmentTemplate[]>('get', `/shippers/${role?.id}/shipment-templates`).then((response) => {
        setTemplates(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch templates. Please contact us.');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, role, api]);

  const onOpenMenu = () => {
    setIsLoading(true);
    onOpen();
  };

  const onTemplateClicked = (template: ShipmentTemplate) => {
    onTemplateClick(template);
    setSelectedTemplateId(template.id);
  };

  const onDeleteTemplate = (template: ShipmentTemplate) => {
    setError(null);
    api<unknown>('delete', `/shipment-templates/${template.id}`).then(() => {
      setError(null);
      setIsLoading(true);
      setDeletedTemplate(true);
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to delete shipment template');
    }).finally(() => {
    });
  };

  return (
    <ShipmentTemplateMenu
      menuOpen={isOpen}
      onOpenMenu={onOpenMenu}
      onCloseMenu={onClose}
      error={error}
      loading={loading}
      templates={templates}
      onTemplateClicked={onTemplateClicked}
      selectedTemplateId={selectedTemplateId}
      onDeleteTemplate={onDeleteTemplate}
      deletedTemplate={deletedTemplate}
      onCloseDeletedTemplate={() => { setDeletedTemplate(false); }}
    />
  );
}
