import { useState } from 'react';

export function useDatasetSliderState(data: object[], desiredStartingValue: number) {
  let initialLanes = 0;
  // eslint-disable-next-line
  for (let i = 0; i < data.length; i++) {
    if (i + 1 === desiredStartingValue) {
      initialLanes = i + 1;
      break;
    }
    if (i < data.length && i + 1 <= desiredStartingValue) {
      initialLanes = i + 1;
    }
  }

  const [sliderValue, setSlidereValue] = useState<number>(initialLanes);

  return { sliderValue, setValue: (value: number) => setSlidereValue(value) };
}
