import { CircularProgress, Stack, Typography } from '@mui/material';
import { PageLayout } from 'layouts';
import { AdminCompanyDetails } from './components/AdminCompanyDetails';

interface Props{
  company: CompanyDetailInfo | null;
  error: string | null;
  loading: boolean;
}

export function CompanyDetails(props: Props) {
  const {
    company, error, loading,
  } = props;

  return (
    <PageLayout>
      <Typography variant="h4" fontWeight={600} mb="1rem">
        Company Details -
        {' '}
        {company?.company.name ?? ''}
      </Typography>

      {loading
      && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {error && (
      <Stack>
        <Typography color="red">{error}</Typography>
      </Stack>
      )}
      { company?.is_admin
        ? <AdminCompanyDetails company={company} />
        : null}
    </PageLayout>
  );
}
