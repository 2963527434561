import {
  Chip,
  FormControl,
  FormControlLabel, InputAdornment, Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { NumberInput } from 'components/NumberInput';
import { WHAT_ARE_MUTLI_SHIPMENT_PROJECTS_BLOG_URL } from '../../../../constants';

interface Props {
  onAvailableLoadsChanged: (newValue: string) => void;
  availableLoads: string;
  onShipmentTypeChanged: (newValue: ShipmentType) => void;
  shipmentType: ShipmentType;
}

export function ShipmentTypeMenu(props: Props) {
  const {
    onAvailableLoadsChanged, availableLoads, onShipmentTypeChanged, shipmentType,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenOption = (event.target as HTMLInputElement).value;
    onShipmentTypeChanged(chosenOption as ShipmentType);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1}>
        <FormControl>
          <RadioGroup
            value={shipmentType}
            onChange={handleChange}
          >
            <FormControlLabel value="SINGLE" control={<Radio />} label="Single Shipment" />
            <Stack direction="row" alignItems="center">
              <FormControlLabel value="MULTI_SHIPMENT_PROJECT" control={<Radio />} label="Multi-Shipment Project" />
              <Chip label="beta" variant="outlined" color="primary" />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
      {shipmentType === 'MULTI_SHIPMENT_PROJECT' && (
      <Stack direction="column" justifyContent="flexStart" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="flexStart" spacing={2}>
          <Stack direction="column" justifyContent="flexStart" spacing={1}>
            <Typography variant="body1">
              How many shipments does this project include?
            </Typography>
            <a target="_blank" href={WHAT_ARE_MUTLI_SHIPMENT_PROJECTS_BLOG_URL} rel="noreferrer">
              <Typography sx={{ textDecoration: 'underline blue', color: 'blue' }}>
                What are Multi-Shipment Projects?
              </Typography>
            </a>
          </Stack>
          <NumberInput
            defaultValue={availableLoads}
            inputLabel="Available Loads"
            onChange={onAvailableLoadsChanged}
            tooltip={shipmentType === 'MULTI_SHIPMENT_PROJECT' ? 'The number of shipments that are included in this project.' : 'Select Multi-Shipment Project to specify shipments available'}
            startAdornment={<InputAdornment position="start">#</InputAdornment>}
          />
        </Stack>

      </Stack>
      )}

    </Stack>
  );
}
