import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { useFilteredViz } from 'hooks';
import { CHART_DEFAULT_HEIGHT, CHART_DEFAULT_WIDTH } from '../constants';
import { TopBookedLanesPieChart } from './TopBookedLanesPieChart';

interface Props {
  filterState: VizFilterState;
  containerWidth: number | undefined;
}

export function TopBookedLanes(props: Props) {
  const {
    filterState, containerWidth,
  } = props;
  const {
    error, vizData,
  } = useFilteredViz<TopBookedLanesResponse>('top-booked-lanes', filterState);

  if (!vizData?.lanes) {
    return <div />;
  }

  const vizContainerHeight = CHART_DEFAULT_HEIGHT;
  const vizHeight = CHART_DEFAULT_HEIGHT * 0.9;
  const vizWidth = containerWidth ? containerWidth * 0.25 : CHART_DEFAULT_WIDTH;

  return (
    <Stack
      id="top-booked-lanes-container"
      direction="column"
      height={vizContainerHeight}
    >
      <Typography variant="h5" textAlign="center">Top Lanes by # of Bookings</Typography>
      <ErrorTypography error={error} />
      <TopBookedLanesPieChart
        data={vizData.lanes}
        width={vizWidth}
        height={vizHeight}
      />
    </Stack>
  );
}
