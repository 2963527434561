import { Stack } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useDatasetSliderState } from 'hooks';
import { DatasetSlider } from '../DatasetSlider';
import { NoDataFoundForFiltersTypography } from '../NoDataFoundForFiltersTypography';
import { numericDataPointValueFormatter } from '../utilts';

interface Props {
  data: AveragePricePerBookedLane[];
  useCAD: boolean;
  width: number;
  height: number;
}

export function AveragePricePerBookedLaneBarChart(props: Props) {
  const {
    data, useCAD, width, height,
  } = props;
  const { sliderValue, setValue } = useDatasetSliderState(data, 10);
  if (data.length === 0) {
    return (
      <NoDataFoundForFiltersTypography />
    );
  }

  const currencySymbol = useCAD ? 'CAD' : 'USD';

  const sortedDataByPrice = data.sort((
    a: AveragePricePerBookedLane,
    b: AveragePricePerBookedLane,
  ) => b.average_price - a.average_price).slice(0, sliderValue);

  const lanes = sortedDataByPrice.map((avgPriceOnLane: AveragePricePerBookedLane) => `${avgPriceOnLane.origin}-${avgPriceOnLane.destination}`);
  const averagePrices = sortedDataByPrice.map(
    (avgPriceOnLane: AveragePricePerBookedLane) => avgPriceOnLane.average_price,
  );

  return (
    <Stack
      direction="column"
      width={width}
      height={height}
    >
      <BarChart
        yAxis={[{ data: lanes, scaleType: 'band' }]}
        xAxis={[
          {
            label: 'Average $', id: 'price', position: 'bottom', valueFormatter: (val) => `$${val}`,
          },
        ]}
        series={[
          {
            data: averagePrices,
            valueFormatter: (val) => (`Average booking $${numericDataPointValueFormatter(val)} ${currencySymbol}`),
            xAxisKey: 'price',
            id: 'price',
          },
        ]}
        layout="horizontal"

      />
      <DatasetSlider
        value={sliderValue}
        onUpdate={(val) => setValue(val)}
        max={data.length}
      />
    </Stack>

  );
}
