import { Box } from '@mui/system';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  children: React.ReactNode
}

export function VizContainer(props: Props) {
  const { children } = props;
  return (
    <Box sx={{
      borderColor: '#cecdcd',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 6,
      p: '1rem',
      mb: '1rem',
    }}
    >
      <ErrorBoundary fallback={(
        <div>
          Something went wrong.
          Please contact us if the isue persists.
        </div>
)}
      >
        {children}
      </ErrorBoundary>
    </Box>
  );
}
