import { Stack } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useFetchingComponentState, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown;
    }
  }
}

interface CompanyAdminExploDashboardResponse {
  jwt: string;
  embed_id: string;
  is_prod: boolean;
  environment: string;
}

interface Props {
  endpoint: string;
  variables?: object | undefined;
}

export function ExploDashboard(props: Props) {
  const { endpoint, variables } = props;
  const { role, getAdminRole } = useRoleContext();
  const [exploClientInit,
    setExploClientInit] = useState<CompanyAdminExploDashboardResponse | null>(null);

  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<CompanyAdminExploDashboardResponse>({
    errorMessage: 'Failed to fetch dashboard',
    fetchConfig: {
      url: endpoint,
    },
  });

  useEffect(() => {
    if (getAdminRole() && !success && error == null) {
      fetch((response) => setExploClientInit(response));
    }
  }, [role, fetch, getAdminRole, success, error]);

  if (role === null || role.type !== 'SHIPPER' || getAdminRole() == null) {
    return <div />;
  }

  return (
    <Stack direction="column">
      <LoadingWrappedNode loading={loading}>
        {exploClientInit && (
          <explo-dashboard
            dash-jwt={exploClientInit.jwt}
            updateUrlParams={false}
            isProduction={exploClientInit.is_prod}
            variables={variables ? JSON.stringify(variables) : undefined}
            environment={exploClientInit.environment}
            id={exploClientInit.jwt}
          />
        )}
        <ErrorTypography error={error} />
      </LoadingWrappedNode>
    </Stack>
  );
}

ExploDashboard.defaultProps = {
  variables: undefined,
};
