import { CircularProgress, Stack } from '@mui/material';
import { ExploShipperPage } from 'components/ExploShipperPage';
import { useExploAccess, useRoleContext } from 'hooks';
import { DataPage } from '../DataPage/DataPage';

export function DataPageSwitcher() {
  const { role } = useRoleContext();
  const { hasAccess } = useExploAccess();

  if (role === null || role.type !== 'SHIPPER') {
    return <div />;
  }

  if (hasAccess === null) {
    return (
      <Stack
        alignItems="center"
        mt="3rem"
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (hasAccess) {
    return <ExploShipperPage />;
  }
  return <DataPage />;
}

// http://127.0.0.1:8000/shippers/126/viz/explo/company-admin-dashboard
