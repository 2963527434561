import { Badge, Email } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  roles: UserRoleInfo[];
}

interface IconTextProps {
  icon: React.ReactNode;
  text: string;
}

function IconText(props: IconTextProps) {
  const { icon, text } = props;
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography ml="0.5rem" variant="h6">{text}</Typography>
    </Box>
  );
}

export function CompanyDetailRoles(props: Props) {
  const { roles } = props;

  return (
    <Box mr="1rem">
      <Box display="inline-flex">
        <Typography variant="h5">
          {roles[0].type}
          S
          <Divider />
        </Typography>
      </Box>
      {roles.map((role) => (
        <Box key={role.user.id}>
          <Box my="1rem">
            <IconText
              icon={<Badge />}
              text={`${role.user.first_name} ${role.user.last_name}`}
            />
            <IconText
              icon={<Email />}
              text={role.user.email}
            />
            <Typography variant="body2" color="text.secondary">
              Account created:
              {' '}
              {ISOStringToLocalReadableTimestamp(role.user.created_at)}
            </Typography>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
}
