import { Button, Typography } from '@mui/material';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useStripeCustomerBillingPortal, useSubscriptionStatus, useWriterComponentState } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { SHIPPER_UPGRADE_RELATIVE_URL } from '../../../../constants';

interface Props {
  buttonSize?: 'small' | 'medium' | 'large';
}

export function ShipperUpgradeNowButton(props: Props) {
  const { buttonSize } = props;
  const { subscription } = useSubscriptionStatus();
  const navigate = useNavigate();
  const {
    write: postShipperInterest,
  } = useWriterComponentState({
    errorMessage: '',
    writeConfig: {
      method: 'post',
      url: '/sales/shipper-premium-interest',

    },
  });

  const {
    requestCustomerBillingPortalSession, loading,
  } = useStripeCustomerBillingPortal({
    onSessionCreatedSuccess: (url) => {
      window.open(url, '_blank');
    },
  });

  const onClick = () => {
    postShipperInterest();

    if (subscription && subscription.subscription_plan === 'SHIPPER_TRIAL') {
      // shippers without an account upgrade from pricing page
      navigate(SHIPPER_UPGRADE_RELATIVE_URL);
    } else {
      // shippers with account uupgrade/update sub through stripe customer portal
      requestCustomerBillingPortalSession();
    }
  };

  return (
    <Button
      size={buttonSize}
      variant="contained"
      color="inherit"
      sx={{
        mr: 2, bgcolor: 'yellow', color: 'yellow', ':hover': 'yellow',
      }}
      onClick={onClick}
    >
      <LoadingWrappedNode loading={loading}>
        <Typography variant="body1" sx={{ color: 'black' }}>
          <b>Upgrade Now</b>
        </Typography>
      </LoadingWrappedNode>
    </Button>
  );
}

ShipperUpgradeNowButton.defaultProps = {
  buttonSize: 'medium',
};
