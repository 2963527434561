import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { useFilteredViz } from 'hooks';
import { CHART_DEFAULT_HEIGHT, CHART_DEFAULT_WIDTH } from '../constants';
import { TotalSpendPerCarrierBarChart } from './TotalSpendPerCarrierBarChart';

interface Props {
  filterState: VizFilterState;
  useCAD: boolean;
  containerWidth: number | undefined;
}

export function TotalSpendPerCarrier(props: Props) {
  const { filterState, useCAD, containerWidth } = props;
  const {
    error, vizData,
  } = useFilteredViz<TotalSpendPerCarrierResponse>('total-spend-per-carrier', filterState);

  if (!vizData?.usd || !vizData?.cad) {
    return <div />;
  }

  const vizContainerHeight = CHART_DEFAULT_HEIGHT;
  const vizHeight = CHART_DEFAULT_HEIGHT * 0.9;
  const vizWidth = containerWidth ? containerWidth * 0.42 : CHART_DEFAULT_WIDTH;

  return (
    <Stack
      id="top-spend-container"
      direction="column"
      height={vizContainerHeight}
    >
      <Typography variant="h5" textAlign="center">Top Carriers by Spend</Typography>
      <ErrorTypography error={error} />
      <TotalSpendPerCarrierBarChart
        data={useCAD ? vizData.cad : vizData.usd}
        useCAD={useCAD}
        width={vizWidth}
        height={vizHeight}

      />
    </Stack>
  );
}
