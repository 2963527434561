import {
  Chip, Stack, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useRoleContext } from 'hooks';
import { CompanyAdminDashboard } from './components/CompanyAdminDashboard';

export function ExploShipperPage() {
  const { role } = useRoleContext();

  if (role === null || role.type !== 'SHIPPER') {
    return <div />;
  }

  return (
    <Box
      id="explo-dashboards-page"
      sx={{
        backgroundColor: '#fefefe',
        height: '100%',
        width: '100%',
        flex: 1,
        borderRadius: 4,
      }}
    >
      <Stack direction="column">
        <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
          <Typography variant="h4" ml="1rem" mt="2rem">Company Admin Data Dashboard</Typography>
          <Chip size="medium" label="BETA" variant="outlined" color="primary" />
        </Stack>
        <CompanyAdminDashboard />
      </Stack>
    </Box>
  );
}
